.canvas {
    align-items: center;
    background: white;
    display: flex;
    flex-direction: column;
}

#logo {
    padding-top: 1em;
    width: 10em
}

.button{
    margin-top: 1em !important;
}
