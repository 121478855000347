body {
    background-color: #e2e8ea;
}

.App {
    display: grid;
    grid-template-columns: 1fr 50em 1fr;
    grid-template-rows: 1fr 30em 1fr;
    font-family: Roboto, sans-serif;
    font-size: 0.9em;
    height: 100vh;
}

.canvas {
    grid-column: 2;
    grid-row: 2;
}
